@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

@mixin mediaQuery($breakpoint: 980px) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@function getVw($value, $breakpoint: 375) {
  @return (100 / $breakpoint) * $value * 1vw;
}

body {
  margin: 0;
  font-family: "Noto Sans JP";

  .header {
    position: fixed;
    width: 100%;
    height: 76px;
    top: 0;
    transition: 0.3s;
    display: flex;
    background-color: #f4fbff;
    box-shadow: 0px 5px 20px #00000033;
    z-index: 10;

    @include mediaQuery {
      height: 58px;
    }

    &.is-hide {
      transform: translateY(-76px);

      @include mediaQuery {
        transform: translateY(-58px);
      }
    }

    .header_logo {
      position: relative;
      width: 174px;
      height: 40px;
      margin: 18px 40px;
      z-index: 2;

      @include mediaQuery {
        width: 122px;
        height: 30px;
        margin: 14px 20px;
      }
    }

    .header_nav {
      flex: 1;
      margin: auto 40px auto auto;

      @include mediaQuery {
        margin-right: 16px;
      }
    }

    .header_nav ul {
      display: flex;
      justify-content: end;
      align-items: center;
      margin: 0;
      padding: 0;
    }

    .header_nav ul li {
      list-style: none;
    }

    .header_nav_link {
      margin-right: 60px;
      text-decoration: none;
      color: #001d7e;

      @include mediaQuery {
        display: none;
      }
    }

    .header_nav_button {
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 44px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      background-color: #001d7e;
      border: 1px solid #001d7e;
      border-radius: 100px;
      box-shadow: 0px 6px 8px #00114b1a;
      text-decoration: none;
      transition: all 0.3s ease;

      &.is-hide {
        opacity: 0;
        pointer-events: none;
      }

      &::before {
        background-color: #fff;
        content: "";
        display: block;
        position: absolute;
        top: -100px;
        left: 0;
        width: 30px;
        height: 100%;
        opacity: 0;
        transition: cubic-bezier(0.32, 0, 0.67, 0);
      }

      &:hover::before {
        animation: anim 0.5s linear 1;
      }

      @keyframes anim {
        0% {
          transform: scale(2) rotate(45deg);
          opacity: 0;
        }
        20% {
          transform: scale(20) rotate(45deg);
          opacity: 0.6;
        }
        40% {
          transform: scale(30) rotate(45deg);
          opacity: 0.4;
        }
        80% {
          transform: scale(45) rotate(45deg);
          opacity: 0.2;
        }
        100% {
          transform: scale(50) rotate(45deg);
          opacity: 0;
        }
      }

      @include mediaQuery {
        width: 142px;
        height: 36px;
        font-size: 12px;
      }
    }

    .header_nav_hamburger {
      display: none;
      position: relative;
      height: 16px;
      width: 20px;
      margin-right: 20px;
      z-index: 2;

      @include mediaQuery {
        display: block;
      }

      span {
        position: absolute;
        left: 15px;
        display: inline-block;
        height: 3px;
        width: 30px;
        background-color: #001d7e;
        transition: all 0.4s;
        border-radius: 2px;

        &:nth-child(1) {
          top: 0px;
        }

        &:nth-child(2) {
          top: 8px;
        }

        &:nth-child(3) {
          top: 16px;
        }
      }

      &.is-active {
        span {
          &:nth-child(1) {
            transform: translateY(8px) rotate(-45deg);
          }
          &:nth-child(2) {
            right: -50%;
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateY(-8px) rotate(45deg);
          }
        }
      }
    }

    .header_hamburger-menu {
      background: top/cover url("/images/background_hamburger-menu.png")
        no-repeat;
      display: none;
      position: absolute;
      width: 100%;
      height: 100vh;
      margin-top: 58px;

      &.is-active {
        display: block;
      }

      & ul {
        padding: 0;
      }

      & ul li {
        text-align: center;
        list-style: none;
        margin-bottom: 50px;

        &:first-child {
          margin-top: 55px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &_link {
        color: #001d7e;
        text-decoration: none;

        font-size: 18px;
        font-weight: bold;
      }

      .header_nav_button {
        font-size: 21px;
        width: 270px;
        height: 60px;
        margin: 0 auto;
      }
    }
  }

  .main-visual {
    position: relative;
    margin-top: 76px;
    margin-bottom: -5px;

    @include mediaQuery {
      margin-top: 58px;
    }
    img {
      width: 100%;
    }

    &_wrapper {
      position: absolute;
      top: 10%;
      right: 0;
      left: 0;
      margin: auto;

      &_text-image {
        display: block;
        text-align: center;

        img {
          width: 80%;
        }
      }

      &_button {
        position: absolute;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: bold;
        background-color: #001d7e;
        border: 1px solid #001d7e;
        border-radius: 100px;
        box-shadow: 0px 6px 8px #00114b1a;
        text-decoration: none;
        top: getVw(590, 1920);
        left: getVw(700, 1920);
        font-size: getVw(34, 1920);
        height: getVw(90, 1920);
        width: getVw(800, 1920);

        &::before {
          background-color: #fff;
          content: "";
          display: block;
          position: absolute;
          top: -100px;
          left: 0;
          width: 30px;
          height: 100%;
          opacity: 0;
          transition: cubic-bezier(0.32, 0, 0.67, 0);
        }

        &:hover::before {
          animation: anim 0.5s linear 1;
        }

        @keyframes anim {
          0% {
            transform: scale(2) rotate(45deg);
            opacity: 0;
          }
          20% {
            transform: scale(20) rotate(45deg);
            opacity: 0.6;
          }
          40% {
            transform: scale(30) rotate(45deg);
            opacity: 0.4;
          }
          80% {
            transform: scale(45) rotate(45deg);
            opacity: 0.2;
          }
          100% {
            transform: scale(50) rotate(45deg);
            opacity: 0;
          }
        }

        @include mediaQuery {
          top: getVw(430);
          left: 0;
          right: 0;
          margin: 0 auto;
          font-size: getVw(12);
          height: getVw(36);
          width: getVw(240);
        }
      }

      &_text {
        position: absolute;
        color: #fff;
        text-decoration: none;
        top: getVw(680, 1920);
        left: getVw(700, 1920);
        font-size: getVw(22, 1920);
        width: getVw(800, 1920);
        text-align: center;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        margin: 15px auto;

        @include mediaQuery {
          top: getVw(480);
          left: 0;
          right: 0;
          margin: 0 auto;
          font-size: getVw(12);
          width: getVw(240);
        }

        p {
          margin: 0;
        }

        p.note {
          margin-top: 5px;
          font-size: 10px;
        }

        br.is-sp {
          display: none;

          @include mediaQuery {
            display: block;
          }
        }
      }
    }
  }

  .solve-problem {
    .problem {
      position: relative;
      background-color: #001d7e;
      text-align: center;
      padding-bottom: 176px;
      clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
      z-index: 2;

      @include mediaQuery {
        padding-bottom: 100px;
        clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
      }

      &_title {
        display: inline-block;
        margin: 90px 0 56px;

        @include mediaQuery {
          margin: 55px 0 40px;
        }
      }

      .problem-list {
        display: flex;
        justify-content: center;

        @include mediaQuery {
          flex-wrap: wrap;
        }

        .problem-item {
          width: 100%;
          max-width: 270px;
          text-align: center;
          margin-right: 65px;

          @include mediaQuery {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
            margin-right: 0;
            width: 100%;
            max-width: none;
          }

          &:nth-child(1) {
            @include mediaQuery {
              margin-top: 0;

              .problem-item_balloon {
                margin: 0 0 0 33px;
              }

              .problem-item_balloon:before {
                top: calc(50% - 15px);
                left: -20px;
                margin-left: -14px;
                border: 15px solid transparent;
                border-right: 20px solid #fff;
              }
            }
          }

          &:nth-child(2) {
            @include mediaQuery {
              .problem-item_image {
                order: 1;
              }

              .problem-item_balloon {
                margin: 0 33px 0 0;
              }

              .problem-item_balloon:before {
                top: calc(50% - 15px);
                left: auto;
                right: -34px;
                border: 15px solid transparent;
                border-left: 20px solid #fff;
              }
            }
          }

          &:last-child {
            margin-right: 0;

            @include mediaQuery {
              .problem-item_balloon {
                margin: 0 0 0 33px;
              }

              .problem-item_balloon:before {
                top: calc(50% - 15px);
                left: -20px;
                margin-left: -14px;
                border: 15px solid transparent;
                border-right: 20px solid #fff;
              }
            }
          }

          &_image {
            display: block;
            max-width: 270px;

            @include mediaQuery {
              max-width: 140px;
            }
          }

          &_balloon {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 132px;
            width: 100%;
            max-width: 270px;
            margin: 45px auto 0;
            background: #fff;
            border-radius: 20px;

            @include mediaQuery {
              height: 120px;
              width: 182px;
            }

            &:before {
              content: "";
              position: absolute;
              top: -35px;
              left: 50%;
              margin-left: -15px;
              border: 15px solid transparent;
              border-bottom: 20px solid #fff;
            }

            & p {
              margin: 0;
              color: #001d7e;
              text-align: center;
              line-height: 31px;

              @include mediaQuery {
                font-size: 14px;
                line-height: 21px;
              }

              br.is-sp {
                display: none;

                @include mediaQuery {
                  display: block;
                }
              }

              br.is-pc {
                @include mediaQuery {
                  display: none;
                }
              }
            }

            & span {
              color: #8db724;
            }
          }
        }
      }
    }

    .solve {
      background: center/cover url("/images/background_solve.png") no-repeat;
      height: 945px;
      margin-top: -124px;
      text-align: center;
      clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);

      @include mediaQuery {
        background: center/cover url("/images/background_solve_sp.png")
          no-repeat;
        clip-path: polygon(100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);
        height: 1500px;
      }

      &-inner {
        max-width: 980px;
        margin: 0 auto;
      }

      &_title {
        display: inline-block;
        margin-top: 200px;
      }

      &_feature {
        display: inline-block;
        margin-top: 74px;

        @include mediaQuery {
          margin-top: 60px;
          width: 100%;
        }
      }

      .solve-list {
        display: flex;
        margin-top: 45px;

        @include mediaQuery {
          flex-wrap: wrap;
          width: 295px;
          margin: 0 auto;
        }

        .solve-item {
          background-color: #eff9ff;
          height: 350px;
          width: 300px;
          border-radius: 20px;
          margin-right: 40px;

          &:last-child {
            margin-right: 0;
          }

          @include mediaQuery {
            margin: 16px auto 0;
            width: 295px;
            height: auto;
          }

          &:first-child {
            @include mediaQuery {
              margin-top: 28px;
            }
          }

          &_image {
            margin-top: 50px;

            @include mediaQuery {
              margin-top: 40px;
              width: 120px;
            }
          }

          &_heading {
            margin: 30px 0 0;
            font-weight: bold;
            color: #001d7e;

            @include mediaQuery {
              margin-top: 24px;
              font-size: 18px;
            }
          }

          &_description {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0 0;
            color: #001d7e;
            font-size: 15px;
            line-height: 24px;
            height: 45px;

            @include mediaQuery {
              height: auto;
              font-size: 14px;
              margin: 7px 0 40px;
            }
          }
        }
      }
    }
  }

  .features {
    background: top center/cover url("/images/background_feature.png") no-repeat;
    margin-top: -150px;
    padding-top: 150px;

    .feature-list {
      margin-top: 150px;

      @include mediaQuery {
        margin-top: 60px;
      }

      .feature-item {
        max-width: 980px;
        margin: 130px auto 0;
        &:first-child {
          margin-top: 0;
        }

        @include mediaQuery {
          margin-top: 64px;
        }

        &_heading {
          text-align: center;

          &_image {
            width: 75px;
          }

          &_title {
            font-size: 46px;
            font-weight: bold;
            color: #101010;
            margin: 16px 0 0;
            line-height: 1;

            @include mediaQuery {
              font-size: 28px;
              margin-top: 10px;
            }
          }

          &_sub-title {
            font-size: 18px;
            font-weight: bold;
            color: #001d7e;
            margin: 12px 0 0;
            line-height: 1;

            @include mediaQuery {
              font-size: 16px;
              margin-top: 10px;
            }
          }
        }

        &_content {
          margin-top: 40px;
          padding: 80px 130px;
          background-color: #fff;
          box-shadow: 0px 10px 10px rgba($color: #000000, $alpha: 0.05);

          @include mediaQuery {
            margin-top: 20px;
            padding: 40px 20px;
          }

          &_image {
            img {
              width: 100%;
            }
          }

          &_text {
            color: #101010;
            font-size: 16px;
            line-height: 32px;
            margin: 40px 0 0;

            @include mediaQuery {
              font-size: 14px;
              line-height: 28px;
              margin-top: 32px;
            }
          }
        }
      }
    }

    .feature-scene {
      background: top center/cover url("/images/background_feature-scene.svg")
        no-repeat;
      margin-top: 170px;
      padding: 85px 0 100px;
      text-align: center;

      @include mediaQuery {
        background: top center/cover
          url("/images/background_feature-scene_sp.svg") no-repeat;
        margin-top: 50px;
        padding-bottom: 60px;
      }

      &_title {
        @include mediaQuery {
          img {
            display: block;
            margin: 0 auto;
          }
        }
      }

      &_image {
        display: inline-block;
        max-width: 980px;
        margin-top: 46px;

        @include mediaQuery {
          max-width: 252px;
          margin: 24px auto 0;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .contact {
    text-align: center;
    padding: 50px 0;

    &_title {
      display: block;
      margin: 0 auto;

      @include mediaQuery {
        height: 38px;
      }
    }

    .button {
      position: relative;
      overflow: hidden;
      display: inline-block;
      text-decoration: none;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      background-color: #001d7e;
      padding: 18px 100px;
      border-radius: 100px;
      margin-top: 32px;

      @include mediaQuery {
        margin-top: 13px;
        padding: 15px 75px;
      }

      &:focus {
        outline: none;
      }

      &::before {
        background-color: #fff;
        content: "";
        display: block;
        position: absolute;
        top: -100px;
        left: 0;
        width: 30px;
        height: 100%;
        opacity: 0;
        transition: cubic-bezier(0.32, 0, 0.67, 0);
      }

      &:hover::before {
        animation: anim 0.5s linear 1;
      }

      @keyframes anim {
        0% {
          transform: scale(2) rotate(45deg);
          opacity: 0;
        }
        20% {
          transform: scale(20) rotate(45deg);
          opacity: 0.6;
        }
        40% {
          transform: scale(30) rotate(45deg);
          opacity: 0.4;
        }
        80% {
          transform: scale(45) rotate(45deg);
          opacity: 0.2;
        }
        100% {
          transform: scale(50) rotate(45deg);
          opacity: 0;
        }
      }
    }

    &_text {
      color: #001d7e;
      font-size: 16px;
      margin: 30px auto 15px;

      @include mediaQuery {
        font-size: 14px;
        margin-top: 21px;
      }

      span {
        font-weight: bold;
        font-size: 28px;
        margin-top: 10px;

        @include mediaQuery {
          font-size: 24px;
          margin-top: 14px;
        }
      }

      p {
        margin: 0;
      }

      p.note {
        margin-top: 5px;
        font-size: 12px;
      }

      br.is-sp {
        display: none;
        @include mediaQuery {
          display: block;
        }
      }
    }
  }

  .case-study {
    background: top center/cover url("/images/background_case-study.png")
      no-repeat;
    padding: 108px 0 93px;

    &_title {
      color: #fff;
      text-align: center;
      font-size: 42px;
      font-weight: bold;
      margin: 0;
    }

    .case-study-list {
      display: flex;
      flex-wrap: wrap;
      margin: 60px auto 0;
      max-width: 980px;

      @include mediaQuery {
        margin: 32px 20px 0;
      }

      .case-study-item {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        max-width: 980px;
        margin-top: 25px;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 60px;
        }

        &_image {
          position: absolute;
        }

        &_text {
          position: relative;
          font-size: 16px;
          line-height: 30px;
          padding: 30px;
          color: #101010;
          background-color: #fff;
          margin: 0 0 0 47px;
          border-radius: 10px;

          @include mediaQuery {
            font-size: 14px;
            line-height: 26px;
          }

          span {
            color: #de0000;
          }
        }

        &.--is-left {
          margin-right: auto;

          .case-study-item {
            &_image {
              left: 0;
            }
            &_text {
              margin-left: 47px + 110px;

              @include mediaQuery {
                margin-left: 69px + 47px;
              }

              &:before {
                content: "";
                position: absolute;
                top: calc(50% - 23px);
                left: -19px;
                margin-left: -15px;
                border: 15px solid transparent;
                border-right: 20px solid #fff;
              }
            }
          }
        }

        &.--is-right {
          margin-left: auto;

          .case-study-item {
            &_image {
              order: 2;
              right: 0;
            }

            &_text {
              margin-left: 0;
              margin-right: 110px + 47px;
              background-color: #e3f4ff;

              @include mediaQuery {
                margin-right: 69px + 47px;
              }

              &:before {
                content: "";
                position: absolute;
                top: calc(50% - 30px);
                right: -34px;
                margin-left: -15px;
                border: 15px solid transparent;
                border-left: 20px solid #e3f4ff;
              }
            }
          }
        }
      }
    }

    &_image {
      max-width: 980px;
      margin: 0 auto;

      @include mediaQuery {
        max-width: 480px;
        padding: 0 20px;
      }

      img {
        width: 100%;
      }
    }
  }

  .faq {
    background: top center/cover url("/images/background_faq.png") no-repeat;
    padding: 110px 0;

    @include mediaQuery {
      background: top center/cover url("/images/background_faq_sp.png")
        no-repeat;
      padding: 60px 0;
    }

    &_title {
      text-align: center;
      font-size: 42px;
      color: #001d7e;
      font-weight: bold;
      margin: 0;

      @include mediaQuery {
        font-size: 28px;
      }
    }

    .faq-list {
      margin-top: 40px;

      .faq-item {
        background-color: #fff;
        box-shadow: 0px 3px 6px rgba($color: #9daeb5, $alpha: 0.4);
        max-width: calc(980px - 60px);
        margin: 24px auto 0;
        padding: 30px;

        @include mediaQuery {
          padding: 25px 20px 20px;
          margin: 16px 20px 0;
        }

        &:first-child {
          margin-top: 0;
        }

        &_question {
          display: flex;
          align-items: center;
          padding-bottom: 12px;
          border-bottom: 1px solid #bfbfbf;

          &_image {
            margin-right: 20px;
            width: 48px;

            @include mediaQuery {
              margin-right: 10px;
              width: 42px;
            }
          }

          &_text {
            margin: 0;
            font-size: 20px;
            color: #001d7e;
            font-weight: 500;

            @include mediaQuery {
              font-size: 16px;
            }
          }
        }

        &_answer {
          display: flex;
          align-items: center;
          padding-top: 12px;

          &_image {
            margin-right: 20px;
            width: 48px;

            @include mediaQuery {
              margin-right: 10px;
              width: 42px;
            }
          }

          &_text {
            margin: 0;
            font-size: 16px;

            @include mediaQuery {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .product-info {
    background: top center/cover url("/images/background_product-info.png")
      no-repeat;
    padding: 110px 0;

    @include mediaQuery {
      background: top center/cover url("/images/background_product-info_sp.png")
        no-repeat;
      padding: 60px 0;
    }

    &_title {
      text-align: center;
      font-size: 42px;
      color: #001d7e;
      font-weight: bold;
      margin: 0 auto;

      @include mediaQuery {
        font-size: 28px;
      }
    }

    &_sub-title {
      text-align: center;
      font-size: 20px;
      color: #001d7e;
      font-weight: bold;
      margin: 81px 0 0 0;

      @include mediaQuery {
        font-size: 14px;
        margin: 33px 0 0 0;
      }
    }

    .product {
      display: flex;
      align-items: flex-end;
      max-width: 980px;
      margin: 32px auto 0;

      @include mediaQuery {
        display: block;
        text-align: center;
        margin-top: 24px;
      }

      &_image {
        height: 100%;
        width: 350px;

        @include mediaQuery {
          margin: 0 auto 24px;
          width: 184px;
        }
      }

      .product-info-list {
        text-align: right;

        @include mediaQuery {
          width: 335px;
          margin: 0 auto;
        }
        .product-info-item {
          display: inline-flex;
          border: 1px solid #dbdbdb;
          border-bottom: none;
          text-align: left;

          &:last-child {
            border-bottom: 1px solid #dbdbdb;
          }

          &_heading {
            background-color: #f5f5f5;
            width: 116px;
            padding: 18px;
            color: #101010;
            font-weight: 500;
            margin: 0;
            border-right: 1px solid #dbdbdb;
            font-size: 16px;

            @include mediaQuery {
              width: 70px;
              padding: 16px;
              font-size: 14px;
            }
          }

          &_text {
            background-color: #fff;
            width: 380px;
            padding: 18px 20px;
            margin: 0px;
            color: #101010;
            font-size: 16px;
            line-height: 26px;

            @include mediaQuery {
              width: 198px;
              padding: 16px;
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }

  .privacy-policy {
    text-align: center;
    font-size: 16px;
    margin-bottom: 110px;

    @include mediaQuery {
      margin-bottom: 60px;
    }

    a {
      color: #001d7e;
    }

    img {
      margin-left: 2px;
    }
  }

  .footer {
    background-color: #000000;

    &_text {
      text-align: center;
      font-size: 14px;
      margin: 0;
      color: #fff;

      &:first-child {
        padding-top: 50px;
        margin-bottom: 16px;

        @include mediaQuery {
          padding-top: 40px;
          margin-bottom: 20px;
        }
      }

      &:last-child {
        padding-bottom: 50px;

        @include mediaQuery {
          padding-bottom: 40px;
        }
      }

      br {
        display: none;

        @include mediaQuery {
          display: block;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    height: 100vh;

    .confirm {
      text-align: center;
      max-width: 616px;
      margin: 0 auto;
      padding: 0 20px;
      color: rgb(69, 90, 100);

      &_check {
        color: rgb(0, 188, 212);
        font-size: 36px;
        margin-bottom: 20px;
      }

      &_heading {
        font-size: 24px;
        margin: 0;
      }

      &_text {
        margin-top: 20px;
        font-size: 16px;
      }

      &_button {
        color: rgb(69, 90, 100);
      }
    }
  }
}
